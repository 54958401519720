<template>
  <div>
    <list :fs="shippingFields" api="shippings" title="Delivery" addLink="/admin/shippings/new" editLink="/admin/shippings" ></list>
  </div>
</template>

<script>
  import List from '@/components/List'
  import { shippingFields } from '@/config'

  export default {
    data() {
      return {
        shippingFields,
      }
    },
    components: {
      List, 
    },

  }
</script>